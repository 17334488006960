@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans:wght@300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100vh;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5 {
  font-family: "Montserrat", sans-serif;
}

ul {
  list-style: none;
}

button, a {
  cursor: pointer;
}

a {
  text-decoration: none;
}

input {
  outline: none;
}

::placeholder {
  font-size: 14px;
  text-transform: capitalize;
}


