@import "../../sass/main.scss";

.compDashboard {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .dashboardMenuOpen {
    margin-left: 235px;
    width: 100%;
  }

  .dashboardMenuClosed {
    margin-left: 45px;
    width: 100%;
  }

  .sidebar {
    background-color: $purple;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    transition: width 0.3s ease;
    width: 100%;

    .lettering {
      align-self: center;
      width: 70%;
    }

    .menuToggle {
      align-self: flex-start;
      background: none;
      border: none;
      color: $white;
      cursor: pointer;
      font-size: 20px;
      outline: none;
      padding: 10px;
    }

    .menuOptions {
      color: $white;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style-type: none;
      margin-top: 10px;
      padding: 0;
      height: 100%;

      li:last-child {
        margin-top: auto;
      }

      li {
        border-radius: 5px;
        font-weight: bold;
        padding: 10px;
        text-align: center;
      }

      .selected,
      li:hover {
        color: $purple2;
        background-color: $white;
      }
    }
  }

  .menu-open {
    width: 235px;
  }

  .menu-closed {
    width: 45px;
  }
}
