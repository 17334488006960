@import "../../sass/main.scss";

.compNotFound {
  align-items: center;
  background-color: $gray1;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  .container {
    align-items: center;
    background-color: $gray2;
    border-radius: 20px;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    padding: 50px 20px;
    text-align: center;

    .hazoImg {
      max-width: 400px;
      width: 100%;
    }

    p {
      margin: 0;
    }
  }
}
