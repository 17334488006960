@import "../../sass/main.scss";

.chart {
  background-color: $gray2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 30px 20px;
  width: 100%;

  .chartBox {
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    height: 400px;
    justify-content: center;
    width: 100%;
  }

  p {
    margin: 10px 0 0 0;
  }
}
