@import "../../../sass/main.scss";

.compCrossing {
  align-items: center;
  background-color: $gray2;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  width: 100%;

  .filterAreaCrossing {
    width: 410px;

    .descriptionFilterArea {
      margin-bottom: 20px;
      width: 100%;

      .descriptionFilter {
        font-size: 16px;
        margin: 0;
      }

      .titleFilter {
        font-size: 22px;
        font-weight: bold;
        margin: 0;
      }
    }

    .firstListCrossing {
      gap: 10px;
      height: 500px;
      overflow-y: scroll;
      padding: 0;
      width: 100%;

      ul {
        padding: 0;
        margin-bottom: 10px;
      }

      .itemCrossing {
        display: flex;
        font-size: 14px;
        gap: 10px;
        padding-left: 10px;
      }

      .selectArea {
        display: flex;
        gap: 10px;

        .selectOptions {
          font-size: 12px;
          cursor: pointer;
        }
      }

      .titleCrossing {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .chartBox {
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 600px;
    justify-content: center;
    width: 100%;
  }
}
