@import "../../sass/main.scss";

.comInsightsReport {
  height: 100vh;
  overflow-y: scroll;
  padding: 5%;

  .messageArea {
    display: flex;
  }

  .message {
    align-self: flex-start;
    border-radius: 8px;
    color: $white;
    margin: 10px;
    padding: 8px 12px;
    width: 100%;
  }

  .iconHazo {
    border-radius: 50%;
    height: 40px;
    margin-top: 18px;
    width: 40px;
  }
}
