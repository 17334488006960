@import "../../sass/main.scss";

.content {
  align-items: center;
  background-color: $gray1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 20px;
  width: 100%;

  h1 {
    font-size: 3rem;
    margin: 0 10% 50px 10%;
  }

  h1,
  h2 {
    font-weight: bold;
  }

  h1,
  h2,
  h3 {
    color: $white;
    margin: 0;
  }

  .block {
    display: flex;
    color: white;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    width: 90%;

    .blocksArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 15px;
      row-gap: 20px;
      justify-content: space-around;

      .anchorBox {
        color: $white;
        text-decoration: none;
      }
    }

    .explanationArea {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 30px;

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .title,
    .subtitle {
      padding: 0 20px;
    }
  }

  .hr {
    border-top: 1px solid white;
    width: 100%;
  }

  .legendBlock {
    width: 90%;
  }
}
