$color121: #609ef1;
$color121-hover: #2165af;
$color121-dark: #287be1;
$black: #000;
$white: #fff;
$white-opacity: #fffffff0;
$sucess-color: #28a745;
$danger-color: #b72025;
$gray-default: #b1b1b1;
$gray-dark: #4d4d4d;
$gray-medium: #7b7b7b;
$gray-light: #f1f1f1;

$black1: #000000;
$black2: #0c0c0d;
$black3: #17171a;

$gray1: #1e1f21;
$gray2: #292a2c;

$purple: #5d55fa;
$purple2: #603fef;
