@import "../../sass/main.scss";

.compSignIn {
  align-items: center;
  background-image: url("../../assets/img/Picture3.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;

  .container {
    align-items: center;
    background-color: rgba($color: $black2, $alpha: 0.8);
    border-radius: 5px;
    color: $white;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    max-width: 580px;
    padding: 30px;

    button {
      background-color: $purple;
      border: none;
      border-radius: 5px;
      color: $white;
      padding: 10px;
    }

    .inputArea {
      position: relative;
      width: 100%;

      input {
        background-color: $black3;
        border: none;
        border-radius: 5px;
        color: $white;
        padding: 10px;
        width: 100%;

        &::placeholder {
          font-family: $font-roboto;
        }

        &::-ms-input-placeholder {
          font-family: $font-roboto;
        }
      }

      .iconEye {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 15px;
        width: 24px;
      }
    }

    .imagesArea {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .imgHazoAi {
        height: 256px;
        padding: 0;
        width: 256px;
      }
      .lettering {
        width: 256px;
      }
    }
  }
}
