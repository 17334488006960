@import "../../../sass/main.scss";

.compBoxChart {
  background-color: $gray2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: space-between;
  padding: 10px;
  text-align: center;
  width: 180px;

  .contentBoxChart {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .chartBoxChart {
    align-content: center;
    display: flex;
    justify-content: center;
    width: 40%;
  }

  .informationsBoxChart {
    align-items: flex-start;
    color: $white;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;

    .titleBoxChart {
      font-size: 14px;
      font-weight: bold;
      height: 50px;
      width: 100%;
    }

    .emphasisBoxChart {
      align-items: center;
      border-radius: 20px;
      display: flex;
      font-size: 12px;
      gap: 5px;
      padding: 0px 10px;

      .arrowBoxChart {
        height: 12px;
      }
    }

    .percentBoxChart {
      font-size: 30px;
    }
  }
}
