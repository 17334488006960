@import "../../sass/main.scss";

.compPromp {
  align-items: center;
  background-color: $gray1;
  display: flex;
  flex-direction: row;
  height: 100vh;
  min-height: 100vh;
  width: 100%;

  .asidePrompt {
    align-items: center;
    border-right: 1px solid $gray2;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 10px;

    button {
      align-items: center;
      background-color: rgba($color: $purple, $alpha: 0);
      border-radius: 5px;
      border: solid 1px $white;
      color: $white;
      display: flex;
      gap: 10px;
      justify-content: center;
      padding: 8px;
      width: 100%;

      .plus {
        height: 15px;
        width: 15px;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      height: 50%;
      margin-top: 10px;
      text-align: center;

      span {
        font-size: 12px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        list-style-type: none;
        overflow-y: auto;
        padding: 0px;
        width: 150px;

        .liSelected {
          border: 1px solid white;
          border-radius: 5px;
        }

        li {
          cursor: pointer;
          padding: 5px;
        }
      }
    }
  }

  hr {
    border-top: 1px solid white;
    width: 100%;
  }

  .contentPrompt {
    color: $white;
    height: 100vh;
    overflow-y: scroll;
    overflow: hidden;
    width: 100%;
  }
}
