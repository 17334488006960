@import "../../sass/main.scss";

.compFilterSelection {
  color: white;
  padding: 10px 0;
  width: 90%;

  h1 {
    margin: 0;
  }

  .groupNavigation {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 5px;
    padding: 0;
    list-style: none;

    .groupItem {
      cursor: pointer;
      padding: 5px 10px;
      text-align: center;
    }

    .groupItemSelected {
      background-color: #1e1f21;
      border-radius: 5px 5px 0 0;
    }
  }
}
