@import "../../sass/main.scss";

.chatComp {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .chatArea {
    height: calc(100vh - 70px);
    max-width: 640px;
    overflow-y: auto;
    padding: 10px;
    width: 100%;

    .errorMessage {
      color: $danger-color;
      text-align: center;
      width: 100%;
    }

    .messageArea {
      display: flex;
    }

    .message {
      align-self: flex-start;
      border-radius: 8px;
      color: $white;
      margin: 10px;
      padding: 8px 12px;
      width: 100%;
    }

    .iconHazo {
      border-radius: 50%;
      height: 40px;
      margin-top: 18px;
      width: 40px;
    }
  }

  .inputContainer {
    align-items: center;
    border: 1px solid $gray2;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    max-width: 640px;
    width: 100%;

    input {
      border: none;
      background-color: rgba($color: #000000, $alpha: 0);
      color: $white;
      flex-grow: 1;
      padding: 8px;
      margin-right: 10px;
    }

    .button {
      padding: 8px 16px;
      background-color: $purple;
      color: $gray1;
      border: none;
      border-radius: 10px;
      cursor: pointer;

      .iconSent {
        height: 24px;
      }
    }

    .button:hover {
      background-color: #0d47a1;
    }

    .buttonDisabled {
      padding: 8px 16px;
      background-color: $gray-dark;
      color: $gray1;
      border: none;
      border-radius: 10px;
      cursor: auto;

      .iconSent {
        height: 24px;
      }
    }

    .buttonDisabled:hover {
      background-color: $gray-dark;
    }
  }
}
